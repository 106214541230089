import Workspace from "@/models/workspace";
import paths from "@/utils/paths";
import showToast from "@/utils/toast";
import {
  ArrowCounterClockwise,
  DotsThree,
  PencilSimple,
  Trash,
  X,
} from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import truncate from "truncate";
import { useTranslation } from "react-i18next";
import { TbLayoutGridRemove } from "react-icons/tb";

const THREAD_CALLOUT_DETAIL_WIDTH = 2;
export default function ThreadItem({
  idx,
  activeIdx,
  isActive,
  workspace,
  thread,
  onRemove,
  toggleMarkForDeletion,
  hasNext,
  ctrlPressed = false,
}) {
  const { t } = useTranslation();
  const { slug } = useParams();
  const optionsContainer = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const linkTo = !thread.slug
    ? paths.workspace.chat(slug)
    : paths.workspace.thread(slug, thread.slug);

  return (
    <div className={`shadow-bg w-[90%] relative flex h-[38px] items-center ml-[20px] my-1 border-none ${isActive ? "workspace-sublist-btn normal-text" : "workspace-sublist-btn-Actv text-black"}`} role="listitem" >
      <div style={{ width: THREAD_CALLOUT_DETAIL_WIDTH + 8 }} className="h-full" />
        <div className="flex w-full items-center justify-between pr-2 group relative">
          {thread.deleted ? (
            <div className="w-full flex justify-between">
              <div className="w-full ">
                <p className={`text-left text-sm normal-text italic`}>
                    {t("sidebar.thread.deleted")}
                </p>
              </div>
              {ctrlPressed && (
                <button type="button" className="border-none" onClick={() => toggleMarkForDeletion(thread.id)}>
                  <ArrowCounterClockwise className="normal-text hover:normal-text" size={18} />
                </button>
              )}
            </div>
          ) : (
            <a href={ window.location.pathname === linkTo || ctrlPressed ? "#" : linkTo } className="w-full flex items-center justify-start gap-2" aria-current={isActive ? "page" : ""}>
              <TbLayoutGridRemove className={`${isActive ? "black-palette" : "normal-text"} h-[15px] w-[15px]`} />
              <p className={`text-left text-sm overflow-hidden whitespace-nowrap overflow-ellipsis ${isActive ? "font-medium black-palette" : "normal-text" }`}>
                  {thread.name === "Thread" ? t("sidebar.thread.empty-thread") : truncate(thread.name, 25)}
              </p>
            </a>
          )}
          {!!thread.slug && !thread.deleted && (
            <div ref={optionsContainer}>
              {ctrlPressed ? (
                <button type="button" className="border-none" onClick={() => toggleMarkForDeletion(thread.id)} >
                  <X className="text-zinc-300 hover:text-white" weight="bold" size={18}/>
                </button>
              ) : (
                <div className="flex items-center w-fit group-hover:visible md:invisible gap-x-1">
                  <button type="button" className="mob-avaible- border-none" onClick={() => setShowOptions(!showOptions)} aria-label="Thread options">
                    <DotsThree className="normal-text" size={30} />
                  </button>
                </div>
              )}
              {showOptions && (
                <OptionsMenu containerRef={optionsContainer} workspace={workspace} thread={thread} onRemove={onRemove} close={() => setShowOptions(false)} />
              )}
            </div>
          )}
        </div>
    </div>
  );
}

function OptionsMenu({ containerRef, workspace, thread, onRemove, close }) {
  const { t } = useTranslation();
  const menuRef = useRef(null);

  // Ref menu options
  const outsideClick = (e) => {
    if (!menuRef.current) return false;
    if (
      !menuRef.current?.contains(e.target) &&
      !containerRef.current?.contains(e.target)
    )
      close();
    return false;
  };

  const isEsc = (e) => {
    if (e.key === "Escape" || e.key === "Esc") close();
  };

  function cleanupListeners() {
    window.removeEventListener("click", outsideClick);
    window.removeEventListener("keyup", isEsc);
  }
  // end Ref menu options

  useEffect(() => {
    function setListeners() {
      if (!menuRef?.current || !containerRef.current) return false;
      window.document.addEventListener("click", outsideClick);
      window.document.addEventListener("keyup", isEsc);
    }

    setListeners();
    return cleanupListeners;
  }, [menuRef.current, containerRef.current]);

  const renameThread = async () => {
    const name = window
      .prompt(t("sidebar.thread.rename-message"))
      ?.trim();
    if (!name || name.length === 0) {
      close();
      return;
    }

    const { message } = await Workspace.threads.update(
      workspace.slug,
      thread.slug,
      { name }
    );
    if (!!message) {
      showToast(`Thread could not be updated! ${message}`, "error", {
        clear: true,
      });
      close();
      return;
    }

    thread.name = name;
    close();
  };

  const handleDelete = async () => {
    if (
      !window.confirm(
        t("sidebar.thread.delete-message")
      )
    )
      return;
    const success = await Workspace.threads.delete(workspace.slug, thread.slug);
    if (!success) {
      showToast(t("show-toast.delete-option"), "error", { clear: true });
      return;
    }
    if (success) {
      showToast(t("show-toast.thread-deleted"), "success", { clear: true });
      onRemove(thread.id);
      return;
    }
  };

  return (
    <div ref={menuRef} className="light-modal-bg absolute w-fit z-[999] top-[15px] right-[0px]  rounded-[6px] p-1" >
      <button onClick={renameThread} type="button" className="sidebar-sub-list w-full rounded-md flex items-center p-2 gap-x-2 normal-text">
        <PencilSimple size={18} />
        <p className="text-sm">{t("sidebar.thread.rename")}</p>
      </button>
      <button onClick={handleDelete} type="button" className="sidebar-sub-list w-full rounded-md flex items-center p-2 gap-x-2 normal-text">
        <Trash size={18} />
        <p className="text-sm">{t("sidebar.thread.delete-thread")}</p>
      </button>
    </div>
  );
}
