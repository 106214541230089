import System from "@/models/system";
import { useState, useEffect } from "react";

export default function OpenRouterOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          OpenRouter API Key
        </label>
        <input type="password" name={`OpenRouterApiKey${moduleSuffix}`} placeholder="OpenRouter API Key" defaultValue={settings?.[`OpenRouterApiKey${moduleSuffix}`] ? "*".repeat(20) : ""} required={true} autoComplete="off" spellCheck={false}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5"
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <OpenRouterModelSelection settings={settings} moduleSuffix={moduleSuffix} />
      )}
    </div>
  );
}

function OpenRouterModelSelection({ settings, moduleSuffix = "" }) {
  const [groupedModels, setGroupedModels] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      setLoading(true);
      const { models } = await System.customModels("openrouter");
      if (models?.length > 0) {
        const modelsByOrganization = models.reduce((acc, model) => {
          acc[model.organization] = acc[model.organization] || [];
          acc[model.organization].push(model);
          return acc;
        }, {});

        setGroupedModels(modelsByOrganization);
      }

      setLoading(false);
    }
    findCustomModels();
  }, []);

  if (loading || Object.keys(groupedModels).length === 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`OpenRouterModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true}>
            -- loading available models --
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select name={`OpenRouterModelPref${moduleSuffix}`} required={true} className="dark-input-mdl normal-text focus:outline-none text-sm rounded-lg block w-full p-2.5">
        {Object.keys(groupedModels)
          .sort() .map((organization) => (
            <optgroup key={organization} label={organization}>
              {groupedModels[organization].map((model) => (
                <option
                  key={model.id}
                  value={model.id}
                  selected={settings?.[`OpenRouterModelPref${moduleSuffix}`] === model.id} className="text-black"
                >
                  {model.name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    </div>
  );
}
