import { useState, useEffect } from "react";
import System from "@/models/system";

export default function MistralOptions({ settings, moduleSuffix = "" }) {
  const [inputValue, setInputValue] = useState(settings?.[`MistralApiKey${moduleSuffix}`]);
  const [mistralKey, setMistralKey] = useState(settings?.[`MistralApiKey${moduleSuffix}`]);

  return (
    <div className="flex gap-x-4">
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Mistral API Key
        </label>
        <input
          type="password"
          name={`MistralApiKey${moduleSuffix}`}
          className="dark-input-mdl normal-text  normal-text text-sm rounded-lg block w-full p-2.5"
          placeholder="Mistral API Key"
          defaultValue={settings?.[`MistralApiKey${moduleSuffix}`] ? "*".repeat(20) : ""}
          required={true}
          autoComplete="off"
          spellCheck={false}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => setMistralKey(inputValue)}
        />
      </div>
      {!settings?.[`credentialsOnly${moduleSuffix}`] && (
        <MistralModelSelection settings={settings} apiKey={mistralKey} moduleSuffix={moduleSuffix} />
      )}
    </div>
  );
}

function MistralModelSelection({ apiKey, settings, moduleSuffix = "" }) {
  const [customModels, setCustomModels] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function findCustomModels() {
      if (!apiKey) {
        setCustomModels([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const { models } = await System.customModels(
        "mistral",
        typeof apiKey === "boolean" ? null : apiKey
      );
      setCustomModels(models || []);
      setLoading(false);
    }
    findCustomModels();
  }, [apiKey]);

  if (loading || customModels.length == 0) {
    return (
      <div className="flex flex-col w-60">
        <label className="normal-text text-sm font-semibold block mb-4">
          Chat Model Selection
        </label>
        <select
          name={`MistralModelPref${moduleSuffix}`}
          disabled={true}
          className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5"
        >
          <option disabled={true} selected={true}>
            {!!apiKey
              ? "-- loading available models --"
              : "-- waiting for API key --"}
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-60">
      <label className="normal-text text-sm font-semibold block mb-4">
        Chat Model Selection
      </label>
      <select
        name={`MistralModelPref${moduleSuffix}`}
        required={true}
        className="dark-input-mdl normal-text  focus:outline-none text-sm rounded-lg block w-full p-2.5"
      >
        {customModels.length > 0 && (
          <optgroup label="Available Mistral Models">
            {customModels.map((model) => {
              return (
                <option
                  key={model.id}
                  value={model.id}
                  selected={settings?.[`MistralModelPref${moduleSuffix}`] === model.id}
                >
                  {model.id}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  );
}
