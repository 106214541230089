import { useContext } from "react";
import { LogoContext } from "../LogoContext";
import { ThemeContext } from "../context/index";

export default function useLogo() {
  const { theme } = useContext(ThemeContext);
  const { 
    logo, 
    setLogo, 
    loginLogo, 
    isCustomLogo, 
    darkModeLogo, 
    setDarkModeLogo, 
    isCustomDarkModeLogo 
  } = useContext(LogoContext);

  const currentLogo = theme === 'light' ? logo : darkModeLogo;

  return { 
    currentLogo, 
    logo, 
    setLogo, 
    loginLogo, 
    isCustomLogo, 
    darkModeLogo, 
    setDarkModeLogo, 
    isCustomDarkModeLogo 
  };
}
