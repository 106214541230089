export default function GenericOpenAiOptions({ settings, moduleSuffix = "" }) {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex gap-4 flex-wrap">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Base URL
          </label>
          <input type="url" name={`GenericOpenAiBasePath${moduleSuffix}`} placeholder="eg: https://proxy.openai.com" defaultValue={settings?.[`GenericOpenAiBasePath${moduleSuffix}`]} required={true} autoComplete="off" spellCheck={false}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            API Key
          </label>
          <input type="password" name={`GenericOpenAiKey${moduleSuffix}`} placeholder="Generic service API Key" defaultValue={settings?.[`GenericOpenAiKey${moduleSuffix}`] ? "*".repeat(20) : ""} required={false} autoComplete="off" spellCheck={false}
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Chat Model Name
          </label>
          <input type="text" name={`GenericOpenAiModelPref${moduleSuffix}`} placeholder="Model id used for chat requests" defaultValue={settings?.[`GenericOpenAiModelPref${moduleSuffix}`]} required={true} autoComplete="off"
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex gap-x-4 flex-wrap">
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Token context window
          </label>
          <input type="number" name={`GenericOpenAiTokenLimit${moduleSuffix}`} placeholder="Content window limit (eg: 4096)" min={1} onScroll={(e) => e.target.blur()} defaultValue={settings?.[`GenericOpenAiTokenLimit${moduleSuffix}`]} required={true} autoComplete="off"
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <label className="normal-text text-sm font-semibold block mb-4">
            Max Tokens
          </label>
          <input type="number" name={`GenericOpenAiMaxTokens${moduleSuffix}`} placeholder="Max tokens per request (eg: 1024)" min={1} defaultValue={settings?.[`GenericOpenAiMaxTokens${moduleSuffix}`] || 1024} required={true} autoComplete="off"
            className="dark-input-mdl normal-text  text-sm rounded-lg block w-full p-2.5 focus:outline-none"
          />
        </div>
      </div>
    </div>
  );
}
